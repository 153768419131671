import http from '../../../utils/services/http'
import {download} from '@/domain/leiloes/services/financeiro'

export const dashboard = () => {
  return http.get(parseInstancia('/api/leiloes/resumeDashboard'))
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const list = (limit, page, filtros, format = null) => {
  let url = '/api/leiloes?page=' + page + '&limit=' + limit + filtros
  let config = {}
  if (format && format !== 'html') {
    config.responseType = 'blob'
    config.timeout = 30000
  }
  return http.get(url, config)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const find = (id) => {
  let url = `/api/leiloes/${id}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const newLeilao = (data) => {
  let url = `/api/leiloes`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updateLeilao = (id, data, method = 'PUT') => {
  let url = `/api/leiloes/${id}`
  let _method = http.put
  if (method && method === 'PATCH') {
    _method = http.patch
  }
  return _method(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updateFotoLeilao = (id, data) => {
  let url = `/api/leiloes/${id}/photo`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const deleteLeilao = (id) => {
  let url = `/api/leiloes/${id}`
  return http.delete(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const deleteFotoLeilao = (id) => {
  let url = `/api/leiloes/${id}/photo`
  return http.delete(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const uploadDocumentoLeilao = (id, data) => {
  let url = `/api/leiloes/${id}/documentos`
  return http.post(url, data, {
    'timeout': 300000,
    // headers: {'Content-Type': 'multipart/form-data'}
  })
    .then(response => {
      return Promise.resolve(response)
    })
    .catch((response) => {
      return Promise.reject(response)
    })
}

export const deleteDocumentoLeilao = (leilao, arquivo) => {
  let url = `/api/leiloes/${leilao}/documentos/${arquivo}`
  return http.delete(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const downloadDocumentoLeilao = (leilao, arquivo) => {
  let url = `/api/leiloes/${leilao}/documentos/${arquivo}/download`
  return http.get(url/*, {
    responseType: 'blob',
    timeout: 30000
  }*/)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const listClassificacao = () => {
  // let url = `/api/leilao/classificao`
  let url = `/api/classificacoesLeilao`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const formasPagamentoLote = () => {
  // let url = `/api/leilao/formasPagamentoLote`
  let url = `/api/formasPagamentoLote`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const motivosCancelamentoVendaLote = () => {
  // let url = `/api/leilao/motivosCancelamentoVendaLote`
  let url = `/api/tiposCancelamentoVenda`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const bancos = () => { // TODO: Move to Financial module
                              // let url = `/api/financeiro/bancos`
  let url = `/api/bancos`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const listLeiloeiros = () => {
  // let url = `/api/leiloeiros`
  let url = `/api/leiloeiros?origem=leilao`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const sistemaTaxas = () => {
  let url = `/api/sistemaTaxacao`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const listLotes = (leilao, limit, page, filtros) => {
  let url = `/api/leiloes/${leilao}/lotes?page=` + page + '&limit=' + limit + filtros
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const listLotesAll = (limit, page, filtros) => {
  let url = `/api/lotes/listarTudo?page=` + page + '&limit=' + limit + filtros
  return http.get(url)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const listLotesCobranca = (leilao, limit, page, filtros) => {
  let url = `/api/leiloes/${leilao}/lotes/cobranca?page=` + page + '&limit=' + limit + filtros
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const listLotes2 = (leilao, lotes) => {
  // let url = `/api/leilao/${leilao}/lotes?page=` + page + '&limit=' + limit + filtros
  let url = '/api/leilaoLotesExemplo2'
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const findLote = (id) => {
  let url = `/api/lotes/${id}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const adicionaLotes = (leilao, bens) => {
  let url = `/api/leiloes/${leilao}/lotes/adicionar`
  return http.post(url, {bens: bens})
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const removeLotes = (leilao, lotes) => {
  let url = `/api/leiloes/${leilao}/lotes/remover`
  return http.post(url, {lotes: lotes})
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const atualizaLote = (id, data, fullUpdate = false) => {
  let url = `/api/leiloes/lotes/${id}`
  if (fullUpdate) {
    url = url + '?fullUpdate=1'
  }
  return http.patch(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}
export const prepararLeilao = (id) => {
  let url = `/api/leiloes/${id}/preparar`
  return http.post(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const atualizaStatusLeilao = (id, status) => {
  id = parseInt(id)
  if (id < 1) return Promise.reject()
  return http.get('/api/leiloes/' + id + '/status/' + status)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const atualizaStatusInternoLeilao = (id, status) => {
  id = parseInt(id)
  if (id < 1) return Promise.reject()
  return http.get('/api/leiloes/' + id + '/statusInterno/' + status)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const atualizaLoteamento = (leilao, lotes) => {
  console.log(lotes)
  let url = `/api/leiloes/${leilao}/atualizaLoteamento`
  return http.post(url, {lotes: lotes})
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const atualizaStatusLotes = (leilao, lotes) => {
  console.log(lotes)
  let url = `/api/leiloes/${leilao}/atualizaStatusLotes`
  return http.post(url, {lotes: lotes})
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const findLance = (id, showDeleted = false) => {
  let url = `/api/lances/${id}`
  if (showDeleted) {
    url = url + '?showDeleted=1'
  }
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const findLanceAutomatico = (id) => {
  let url = `/api/lancesAutomaticos/${id}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const atualizaLance = (id, data) => {
  let url = `/api/lances/${id}`
  return http.patch(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const atualizaStatusLote = (id, data) => {
  return http.post(`/api/cmd/lotes/${id}/status`, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

export const registrarPagamentoLote = (data) => {
  return http.post(`/api/financeiro/pagamentoLote`, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

export const findPagamentoLote = (id) => {
  return http.get(`/api/financeiro/pagamentoLote/${id}`)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

export const atualizaPagamentoLote = (id, data) => {
  return http.patch(`/api/financeiro/pagamentoLote/${id}`, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

export const cancelarPagamentoLote = (id, estornar = true) => {
  return http.delete(`/api/financeiro/pagamentoLote/${id}?estornar=${estornar ? 1 : 0}`)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

export const estatisticas = (id) => {
  let url = `/api/leiloes/${id}/estatisticas`
  return http.get(parseInstancia(url))
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const getLeilaoResumo = (leilao) => {
  return http.get(parseInstancia('/api/leiloes/' + leilao + '/relatorios/resumo'))
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const relatorioSaida = (leilao) => {
  return http.get(parseInstancia('/api/leiloes/' + leilao + '/relatorios/saida'))
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}
export const relatorioSaidaArrematante = (leilao) => {
  return http.get(parseInstancia('/api/leiloes/' + leilao + '/relatorios/saidaArrematante'))
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}
export const relatorioLancesPorLeilao = (leilao) => {
  return http.get(parseInstancia('/api/leiloes/' + leilao + '/relatorios/lances'))
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}
export const relatorioLancesLeilaoPorComitente = (leilao) => {
  return http.get(parseInstancia('/api/leiloes/' + leilao + '/relatorios/lancesPorComitente'))
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const relatorioCertidaoPublicacao = (leilao) => {
  return http.get(parseInstancia('/api/leiloes/' + leilao + '/relatorios/certidaoPublicacao'))
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const relatorioCertidaoNegativaLeilao = (leilao) => {
  return http.get(parseInstancia('/api/leiloes/' + leilao + '/relatorios/certidaoNegativa'))
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const relatorioCertidaoNegativaLeilao2 = (leilao) => {
  return http.get(parseInstancia('/api/leiloes/' + leilao + '/relatorios/certidaoNegativa2'))
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const relatorioNumerosVisitas = (leilao) => {
  return http.get(parseInstancia('/api/leiloes/' + leilao + '/relatorios/numerosVisitas'))
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

function getQueryParam(param) {
  const url = window.location.href;
  const parts = url.split('?');
  if (parts.length < 2) {
    return null;
  }
  const queryString = parts[1].split('#')[0];
  const params = new URLSearchParams(queryString);
  return params.get(param)
}

function parseInstancia (url) {
  const instancia = getQueryParam('leilaoInstancia')
  if (instancia) {
    const parts = url.split('?');
    // Se não houver "?"
    if (parts.length < 2) {
      url = url + '?leilaoInstancia=' + instancia
    } else {
      url = url + '&leilaoInstancia=' + instancia
    }
  }
  return url
}

export const relatorioDiarioLeilao = (leilao) => {
  let url = parseInstancia('/api/leiloes/' + leilao + '/relatorios/diarioLeilao')
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const relatorioDiarioLeilaoV2 = (leilao) => {
  return http.get(parseInstancia('/api/leiloes/' + leilao + '/relatorios/diarioLeilaoV2'))
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const relatorioLotesNaoVendidos = (leilao) => {
  return http.get(parseInstancia('/api/leiloes/' + leilao + '/relatorios/lotesNaoVendidos'))
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const relatorioLotesCondicional = (leilao) => {
  return http.get(parseInstancia('/api/leiloes/' + leilao + '/relatorios/lotesCondicional'))
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const relatorioLotesVendidos = (leilao) => {
  return http.get(parseInstancia('/api/leiloes/' + leilao + '/relatorios/lotesVendidos'))
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const relatorioLotesRetirados = (leilao) => {
  return http.get(parseInstancia('/api/leiloes/' + leilao + '/relatorios/lotesRetirados'))
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const relatorioTermoAlienacao = (leilao) => {
  return http.get(parseInstancia('/api/leiloes/' + leilao + '/relatorios/termoAlienacao'))
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const emissaoTermoAlienacao = (lotes) => {
  return http.post('/api/lotes/documentos/termoAlienacao', {
    lotes: lotes
  })
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const emissaoAutoPositivoArremate = (lotes) => {
  return http.post('/api/lotes/documentos/autoPositivoArrematacao', {
    lotes: lotes
  })
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const emissaoAutoNegativoArremate = (lotes) => {
  return http.post('/api/lotes/documentos/certidaoNegativa', {
    lotes: lotes
  })
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const emissaoAutoNegativoArremate2leilao = (lotes) => {
  return http.post('/api/lotes/documentos/certidaoNegativa2', {
    lotes: lotes
  })
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const relatorioArrematacaoLeilao = (leilao, version = 'v1') => {
  return http.get(parseInstancia('/api/leiloes/' + leilao + '/relatorios/arrematacaoLeilao?version=' + version))
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const transferirArremate = (lote, arrematante, tipo = 'comprador') => {
  let url = `/api/lotes/${lote}/transferir/${arrematante}?tipo=${tipo}`
  return http.post(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const gerarCobrancaLote = (lote) => {
  return http.get(parseInstancia(`/api/lotes/${lote}/gerarCobranca`))
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const gerarFormalizacaoLote = (lote) => {
  return http.get(parseInstancia(`/api/lotes/${lote}/gerarFormalizacao`))
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const listaConfiguracoesLancesAutomaticos = (leilao, limit, page, filtros) => {
  let url = `/api/leiloes/${leilao}/lances-automaticos?page=${page}&limit=${limit}${filtros}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const excluirConfiguracaoLanceAutomatico = (id) => {
  let url = `/api/lancesAutomaticos/${id}`
  return http.delete(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const logOperacaoLeilao = (leilao) => {
  return http.get(parseInstancia(`/api/monitor/leiloes/${leilao}/logs`))
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const importarLotes = (leilao, data, exportar = false) => {
  let url = `/api/leiloes/${leilao}/importarLotes`
  let config = {
    'timeout': 300000
  }
  if (exportar) {
    url = url + '?export=1'
    config.responseType = 'blob'
  }
  return http.post(url, data, config)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch((response) => {
      return Promise.reject(response)
    })
}

export const executarImportacao = (leilao, importacao) => {
  let url = `/api/leiloes/${leilao}/importacao/${importacao}/executar`
  return http.post(url, {
    // headers: {'Content-Type': 'multipart/form-data'}
  })
    .then(response => {
      return Promise.resolve(response)
    })
    .catch((response) => {
      return Promise.reject(response)
    })
}

export const copiarLotesParaOutroLeilao = (leilao, lotes, leilaoDestino) => {
  let url = `/api/leiloes/${leilao}/lotes/copiar`
  return http.post(url, {lotes, leilaoDestino})
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const relatorioLeiloesCompelto = (limit, page, filtros, format = 'html') => {
  let url = '/api/relatorio/leiloes/completo?' + filtros
  let config = {}
  if (format && format !== 'html') {
    config.responseType = 'blob'
    config.timeout = 30000
  }
  return http.get(url, config)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const findLancesApagados = (id) => {
  let url = `/api/lotes/${id}/lances-deletados`
  return http.get(url)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const sincronizarSites = (id) => {
  let url = `/api/leiloes/${id}/sincronizarSites`
  return http.get(url)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const gerarEdital = (id) => {
  let url = `/api/leiloes/${id}/gerarEdital`
  return download(url)
}

export const avisaInicioLeilao = (id, data) => {
  let url = `/api/leiloes/${id}/enviarAvisoInicioLeilao`
  return http.post(url, data)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const atualizarValoresLeilao = (id, data) => {
  let url = `/api/leiloes/${id}/atualizarValoresLeilao`
  return http.post(url, data)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const geraNotasEmMassa = (id, email) => {
  let url = `/api/financeiro/leilao/${id}/geraZipNotasArrematacao`
  return http.post(url, {
    email: email
  })
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const listLoteIds = (leilao) => {
  return http.get(`/api/leiloes/${leilao}/lotes-ids`)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const atualizaDescricoesBens = (leilao) => {
  return http.post(`/api/leiloes/${leilao}/atualizaDescricoesBens`)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const atualizaStatusLotesEmMassa = (leilao, data) => {
  let config = {}
  config.timeout = 120000
  return http.post(`/api/leiloes/${leilao}/atualizaStatusLotesEmMassa`, data, config)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const listLeiloesNegativos = (limit, page, filtros, format = null) => {
  let url = '/api/relatorio/leiloes-negativos?page=' + page + '&limit=' + limit + filtros
  let config = {}
  if (format && format !== 'html') {
    config.responseType = 'blob'
    config.timeout = 30000
  }
  return http.get(url, config)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}
export const stats = (leilao) => {
  let url = `/api/leiloes/${leilao}/stats`
  return http.get(parseInstancia(url))
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export default {
  dashboard,
  list,
  find,
  listClassificacao,
  listLeiloeiros,
  sistemaTaxas,
  listLotes,
  listLotes2, // TMP
  findLote,
  formasPagamentoLote,
  motivosCancelamentoVendaLote,
  listaConfiguracoesLancesAutomaticos,
  excluirConfiguracaoLanceAutomatico
}
